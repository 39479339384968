import axios from "axios";
import { setDocuments } from "./localbase";

export default {
    /**
     *
     * @param userID id of the logges user
     * @returns array of documenti
     *
     * Get all documents for the logged user
     */
    async getDocumenti(dipendenteID: string) {
        const data = new FormData();
        data.append("limit", "50");
        data.append("where[documenti_dipendenti_dipendente]", dipendenteID.toString());
        data.append("orderby", "documenti_dipendenti_creation_date");
        data.append("orderdir", "desc");
        console.log(`${process.env.VUE_APP_BASE_URL}`);

        const response = await axios.post(`rest/v1/search/documenti_dipendenti`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        // console.log(response);
        //localStorage.setItem('richieste', JSON.stringify(response.data.data));
        // Save into indexeddb => dbDocuments
        // con chiave id documento_dipendente e valore il documento
        response.data.data.forEach((document) => {
            setDocuments(document);
        });

        //console.log(response);
        return response.data.data;
    },

    async getCategorieDocumenti() {
        const data = new FormData();
        data.append("orderby", "documenti_dipendenti_categoria_value");
        data.append("orderdir", "asc");

        const response = await axios.post(`rest/v1/search/documenti_dipendenti_categoria`, data, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        //console.log(response);
        return response.data.data;
    },
};
